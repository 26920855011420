import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {PermissionService} from '@core/services/global/permission.service';
import {Permission, UserRoleModel} from '@core/models/user/userrole.model';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard {
    constructor(private readonly permissionService: PermissionService,
                private readonly router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const routeData = route.data.permission;
        let hasPermission = true;
        let permissions;

        if (Array.isArray(routeData)) {
            permissions = [...routeData.map((x: PermissionGuardClause) => x.permission)];
        } else {
            permissions = [routeData.permission];
        }

        permissions.forEach((permission) => {
            if (isDevMode() && this.isInvalidPermission(permission)) {
                console.warn(`Invalid permission guard clause in route ${route.url} - expected PermissionGuardClause, got '${routeData?.permission ?? routeData}'`);
            }

            if (!this.permissionService.hasPermission(permission)) {
                hasPermission = false;
            }
        });

        if (!hasPermission) {
            return this.router.parseUrl('error/unauthorized');
        }

        return true;
    }

    private isInvalidPermission = (permission: string): boolean => !permission || !(permission in new UserRoleModel());

}

export class PermissionGuardClause {
    constructor(public permission: Permission) {
    }
}
